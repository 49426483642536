import './Welcome.scss'

import { OpenSelectWallet, WalletContext } from '../contexts';
import React, { useContext, useEffect } from 'react';

import {Button} from "antd";
import { useNavigate } from 'react-router-dom';

interface Props {
}

function Welcome ({}: Props): React.ReactElement<Props> {
  const selectWallet = useContext(OpenSelectWallet);
  const walletContext = useContext(WalletContext);
  const navigate = useNavigate()

  useEffect(() => {
    if (walletContext.wallet && walletContext.accounts.length > 0) {
      navigate('/wallet-info')
    }
  }, [walletContext.wallet])

  return (
		<div className={"welcome-wrapper"}>
			<div className={"welcome-content"}>
				<img
					src="/explora-logo.png"
					alt="Explora Online"
					width="300px"
				/>
				<Button
					className="ant-btn ant-btn-primary sub-wallet-btn sub-wallet-btn-small-size"
					onClick={selectWallet.open}
				>
					Connect Wallet
				</Button>
				{/* <p>Supported browsers: Chrome, Brave, Firefox</p> */}
			</div>
		</div>
  );
}

export default Welcome
